import React from "react";
import { css } from "@emotion/core";
import { Link } from "react-router-dom";
import axios from "axios";

const Navbar = ({ onNewPageClick }) => {
  return (
    <ul
      css={css`
        list-style: none;
        margin: 0;
        padding: 15px 25px;
        background-color: #000;
        li {
          display: inline;
          margin: 0;
          margin-right: 35px;
          font-size: 18px;
        }

        a {
          text-decoration: none;
          color: #fff;
        }
      `}
    >
      <li>
        <Link
          css={css`
            margin-right: 50px;
            font-size: 20px;
          `}
          to="/"
        >
          <strong>Pointers</strong>
        </Link>
      </li>
      <li>
        <Link to="/">Home</Link>
      </li>
      <li>
        <button
          css={css`
            all: unset;
            color: white;
            cursor: pointer;
          `}
          onClick={onNewPageClick}
        >
          New Page
        </button>
      </li>
    </ul>
  );
};

export default Navbar;
