import React, { useEffect, useState } from "react";
import { css } from "@emotion/core";
import axios from "axios";
import Navbar from "./Navbar";
import { AddAnswerModal, AddNewPageModal, AddSectionModal } from "./Modal";
const Landing = ({}) => {
  const [pages, setPages] = useState([]);
  const [showNewPageModal, setShowNewPageModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const onNewPageClick = () => {
    setShowNewPageModal(true);
  };

  const fetchPages = () => {
    console.log("Fetchpages");
    axios.get(`https://api.pointers.wiki/pages`).then((res) => {
      console.log(res);
      setPages(res.data.pages);
      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    fetchPages();
  }, []);

  return (
    <div
      css={css`
        font-family: Arial, Helvetica, sans-serif;
        height: 100vh;
        width: 100vw;

        p,
        blockquote {
          font-size: 18px;
        }
      `}
      onClick={() => {
        if (showNewPageModal) {
          setShowNewPageModal(false);
        }
      }}
    >
      <Navbar onNewPageClick={onNewPageClick} />
      {showNewPageModal && (
        <AddNewPageModal
          fetchPages={fetchPages}
          closeModal={() => setShowNewPageModal(false)}
        />
      )}
      <div
        css={css`
          background-color: white;
          max-width: 800px;
          margin: 0 auto;
          margin-top: 30px;
        `}
      >
        <h1>Welcome to Pointers</h1>
        <p>Crowdsourced pointers to knowledge from experts.</p>
        {loading && <h1>Loading...</h1>}
        {pages.map((page) => (
          <>
            <a href={"/page/" + page.slug}>
              <h2>{page.title}</h2>
            </a>
          </>
        ))}
      </div>
    </div>
  );
};

export default Landing;
