import React from "react";
import { css } from "@emotion/core";

const Button = ({ children, ...props }) => (
  <button
    css={css`
      background: white;
      border: 2px solid black;
      padding: 8px 5px;
      font-size: 18px;
      border-radius: 5px;
      cursor: pointer;
      /* width: 150px; */

      &:hover,
      &:active {
        background-color: lightgrey;
        /* color: white; */
      }
    `}
    {...props}
  >
    {children}
  </button>
);

export default Button;
