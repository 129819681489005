import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Home from "./Home";
import Landing from "./Landing";

const App = () => {
  return (
    <Router>
      <Switch>
        <Route path="/page/:slug" component={Home} />
        <Route path="/" component={Landing} />
      </Switch>
    </Router>
  );
};

export default App;
