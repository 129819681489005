import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { css } from "@emotion/core";
import axios from "axios";
import Navbar from "./Navbar";
import { AddAnswerModal, AddNewPageModal, AddSectionModal } from "./Modal";
import Button from "./Button";
import ButtonGroup from "./ButtonGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const Home = ({}) => {
  const [showAddAnswerModal, setShowAddAnswerModal] = useState(false);
  const [showAddSectionModal, setAddSectionModal] = useState(false);
  const [showNewPageModal, setShowNewPageModal] = useState(false);

  const [activeSection, setActiveSection] = useState("");
  const [sections, setSections] = useState([]);
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);

  const { slug } = useParams();

  const onNewPageClick = () => {
    setShowNewPageModal(true);
  };

  const fetchData = () => {
    axios.get(`https://api.pointers.wiki/page/${slug}`).then((res) => {
      console.log(res);
      setLoading(false);
      setTitle(res.data.title);
      setSections(res.data.data);
    });
  };

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);

  return (
    <div
      css={css`
        font-family: Arial, Helvetica, sans-serif;
        height: 100vh;
        width: 100vw;

        a {
          color: #1b1b1b;
        }

        p,
        blockquote {
          font-size: 18px;
          border-left: 3px solid #adabac;
          padding-left: 15px;
        }
      `}
      onClick={() => {
        if (showAddAnswerModal) {
          setShowAddAnswerModal(false);
        }
        if (showAddSectionModal) {
          setAddSectionModal(false);
        }
        if (showNewPageModal) {
          setShowNewPageModal(false);
        }
      }}
    >
      <Navbar onNewPageClick={onNewPageClick} />
      {showNewPageModal && (
        <AddNewPageModal closeModal={() => setShowNewPageModal(false)} />
      )}
      {showAddAnswerModal && (
        <AddAnswerModal
          section={activeSection}
          slug={slug}
          fetchData={fetchData}
          closeModal={() => setShowAddAnswerModal(false)}
        />
      )}
      {showAddSectionModal && (
        <AddSectionModal
          slug={slug}
          fetchData={fetchData}
          closeModal={() => setAddSectionModal(false)}
        />
      )}
      <div
        css={css`
          background-color: white;
          max-width: 800px;
          margin: 0 auto;
          margin-top: 30px;
        `}
      >
        {loading && <h1>Loading...</h1>}
        <h1>{title}</h1>

        {sections.map((section) => (
          <>
            <h2>
              <span
                css={css`
                  margin-right: 10px;
                  font-weight: 400;
                  font-size: 16px;
                  vertical-align: middle;
                  color: #d2d2d2;

                  display: inline-flex;
                  flex-direction: column;
                  justify-content: center;
                `}
                onClick={() => {
                  const confirmDelete = window.confirm(
                    `Are you sure you want to delete the section for "${section.section}" ?`
                  );
                  if (confirmDelete) {
                    axios
                      .delete(`https://api.pointers.wiki/remove/section`, {
                        data: {
                          section: section.section,
                          page: slug,
                        },
                      })
                      .then(() => {
                        fetchData();
                      });
                  }
                }}
              >
                <FontAwesomeIcon
                  css={css`
                    height: 12px;
                  `}
                  icon={faTimes}
                />
              </span>
              {section.section}
            </h2>

            {section.answers.map((answer) => (
              <div>
                <h3
                  css={css`
                    font-weight: 400;
                  `}
                >
                  {" "}
                  <span
                    css={css`
                      margin-right: 10px;
                      font-weight: 400;
                      font-size: 16px;
                      vertical-align: middle;
                      color: #d2d2d2;

                      display: inline-flex;
                      flex-direction: column;
                      justify-content: center;
                    `}
                    onClick={() => {
                      const confirmDelete = window.confirm(
                        `Are you sure you want to delete the answer "${answer.title} by ${answer.attribution}" ?`
                      );
                      if (confirmDelete) {
                        axios
                          .delete(
                            `https://api.pointers.wiki/remove/answer/${answer.id}`
                          )
                          .then(() => {
                            fetchData();
                          });
                      }
                    }}
                  >
                    <FontAwesomeIcon
                      css={css`
                        height: 12px;
                      `}
                      icon={faTimes}
                    />
                  </span>
                  <a target="_blank" href={answer.url}>
                    {answer.title}
                  </a>{" "}
                  by {answer.attribution}
                </h3>{" "}
                <blockquote>{answer.excerpt}</blockquote>
              </div>
            ))}

            <ButtonGroup>
              <Button
                onClick={() => {
                  setActiveSection(section.section);
                  setShowAddAnswerModal(!showAddAnswerModal);
                }}
              >
                Add Source
              </Button>
            </ButtonGroup>
          </>
        ))}

        <ButtonGroup>
          <Button
            css={css`
              margin-top: 15px;
            `}
            onClick={() => setAddSectionModal(!showAddSectionModal)}
          >
            Add Section
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
};

export default Home;
