import React, { useState } from "react";
import { css } from "@emotion/core";
import "./Modal.css";
import axios from "axios";
import Button from "./Button";

const Modal = ({ children }) => (
  <div
    css={css`
      display: flex;
      justify-content: center;
      position: fixed;
      top: 30%;
      left: 50%;
      width: 500px;
      margin-top: -150px;
      margin-left: -250px;
      background-color: white;
      color: black;
      border: 3px solid #000;
      border-radius: 5px;
      padding: 40px 10px;

      h1 {
        margin-top: 0;
      }

      label,
      input {
        font-size: 18px;
      }

      label {
        margin-top: 10px;
      }

      input {
        margin-top: 5px;

        padding: 5px;
      }

      button {
        margin-top: 20px;
      }

      textarea {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 18px;
      }
    `}
    onClick={(e) => {
      e.stopPropagation();
    }}
  >
    {children}
  </div>
);

export const AddAnswerModal = ({ section, slug, closeModal, fetchData }) => {
  return (
    <Modal>
      <div className="Modal-column">
        <h1>Add content block</h1>
        <label>Title</label>
        <input id="title" type="text" />
        <label>Excerpt</label>
        <textarea
          rows={4}
          id="excerpt"
          className="Modal-textarea"
          type="text"
        />
        <label>Source Name</label>
        <input id="source" type="text" />
        <label>Source URL</label>
        <input id="url" type="text"></input>
        <Button
          className="Modal-button"
          onClick={() => {
            axios
              .post("https://api.pointers.wiki/submit/answer", {
                section,
                page: slug,
                title: document.getElementById("title").value,
                excerpt: document.getElementById("excerpt").value,
                attribution: document.getElementById("source").value,
                url: document.getElementById("url").value,
              })
              .then(() => {
                fetchData();
              });
            closeModal();
          }}
        >
          Contribute
        </Button>
      </div>
    </Modal>
  );
};
export const AddSectionModal = ({ slug, closeModal, fetchData }) => {
  const [title, setTitle] = useState("");
  return (
    <Modal>
      <div className="Modal-column">
        <h1>Add New Section</h1>
        <label>Title</label>
        <input type="text" onChange={(e) => setTitle(e.target.value)} />
        <Button
          className="Modal-button"
          onClick={() => {
            axios
              .post("https://api.pointers.wiki/submit/section", {
                page: slug,
                title: title,
              })
              .then(() => {
                fetchData();
              });
            closeModal();
          }}
        >
          Add Section
        </Button>
      </div>
    </Modal>
  );
};
export const AddNewPageModal = ({ section, slug, closeModal, fetchPages }) => {
  const [title, setTitle] = useState("");
  return (
    <Modal>
      <div className="Modal-column">
        <h1>Add New Page</h1>
        <label>Title</label>
        <input type="text" onChange={(e) => setTitle(e.target.value)}></input>
        <Button
          className="Modal-button"
          onClick={() => {
            axios
              .post("https://api.pointers.wiki/submit/page", {
                title,
              })
              .then(() => {
                fetchPages();
              });
            closeModal();
          }}
        >
          Add Page
        </Button>
      </div>
    </Modal>
  );
};
