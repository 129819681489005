import React from "react";
import { css } from "@emotion/core";

const ButtonGroup = ({ children }) => (
  <div
    css={css`
      display: flex;
      flex-direction: column;
      width: 100%;
    `}
  >
    {children}
  </div>
);

export default ButtonGroup;
